/*
 * @Description:
 * @Author: luckymiaow
 * @Date: 2022-11-07 09:09:51
 * @LastEditors: luckymiaow
 */
import axios, { AxiosResponse } from 'axios';

export type IsBasicType<T> = T extends
  | string
  | number
  | boolean
  | string[]
  | number[]
  | null
  | undefined
  | Date
  | Date[]
  | never
  ? true
  : false;

export type AddPrefixToNestedKeys<T> = {
  [K in keyof T as IsBasicType<T[K]> extends true ? never : `$${string & K}`]: Record<keyof T[K], string>;
};

declare global {
  export type WithPrefix<T> = Record<keyof T, string> & AddPrefixToNestedKeys<T>;
}

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'PATCH'; // | "TRACE";
export type ApiResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';

export interface RequestOptions<TData> {
  method: HttpMethod;
  url: string;
  params?: { [key: string]: any } | null;
  headers?: { [key: string]: any };
  contentTypes?: string[];
  responseType?: ApiResponseType;
  data?: TData;
}

export interface LimitedAttribute {
  id: string;
  type?: number;
  displayName?: string | null;
  description?: string | null;
  logEnabled?: boolean;
  [key: string]: string | number | boolean | Date | null | undefined;
}

export interface ApiActionDecorator<T> {
  (target: T, methodName: string, descriptor: PropertyDescriptor): void;
}

export interface IApiOptions {
  baseURL?: string;
  ajaxRequestAsync<TData, TResult>(options: RequestOptions<TData>): Promise<TResult | AxiosResponse<TResult>>;
  throwOnApiFaulted(state: any): void;
}

export class DefaultApiOptions implements IApiOptions {
  baseURL?: string;
  async ajaxRequestAsync<TData, TResult>(options: RequestOptions<TData>): Promise<TResult> {
    const { data } = await axios.request<TData, AxiosResponse<TResult>>({
      ...options,
      baseURL: this.baseURL,
    });
    return data;
  }
  throwOnApiFaulted(state: any): void {
    throw 'api faulted: ' + JSON.stringify(state);
  }
}

export const apiOptions: IApiOptions = new DefaultApiOptions();

export interface IAuthorizationService {
  enabled: boolean;
  authorize(limitId: string): boolean;
}

export const authorizationService: IAuthorizationService = {
  enabled: true,
  authorize(limitId: string): boolean {
    console.log(`authorizing: ${limitId}`);
    return true;
  },
};

export class UnauthorizedAccessException extends Error {
  resource: LimitedAttribute;
  constructor(resource: LimitedAttribute) {
    super(`[无权访问资源] 标识：${resource.id}，名称：${resource.displayName}`);
    this.resource = resource;
  }
}

export function ApiAction<T>(resource: LimitedAttribute): ApiActionDecorator<T> {
  return function (target: T, methodName: string, descriptor: PropertyDescriptor): void {
    const method = descriptor.value;
    descriptor.value = function (...args: any[]) {
      let granted = true;
      try {
        granted = authorizationService.authorize(resource.identifier as string);
      } catch (ex) {
        console.log('authorize error', ex);
      }
      if (!granted) {
        throw new UnauthorizedAccessException(resource);
      }
      const result = method!.apply(this, args);
      return result;
    };

    descriptor.value.resource = resource;
  };
}

/*
 * @Description:
 * @Author: nono
 * @Date: 2023-06-28 11:52:21
 * @LastEditors: 景 彡
 * @LastEditTime: 2023-09-25 16:47:44
 */

import type { Ref } from 'vue';
import { onMounted, ref } from 'vue';
import * as api from '@/api';
import * as models from '@/api/models';

export class News {
  id: string | null | undefined = null;
  title: string | null | undefined = null;
  date: string | null | undefined = null;
}

export class AllInformation {
  tabName: string | null | undefined = null;
  news: models.Information[] = [];
  total?: number;
}

export interface FetchDataParams {
  categoryName?: string | string[];
  categoryType?: models.CategoryType;
  displayName?: string;
  isCover?: boolean;
  isTop?: boolean;
  offset?: number;
}

export function useFetchData(
  params: FetchDataParams[],
  limit?: number
): { data: Ref<AllInformation[]>; onLoad(fn: Function): void };

export function useFetchData(
  params: FetchDataParams,
  limit?: number
): { data: Ref<AllInformation>; onLoad(fn: Function): void };

export function useFetchData(params: FetchDataParams[] | FetchDataParams, limit?: number) {
  // 根据name去查询对应的分类，然后返回对应的分类信息
  const data = ref<AllInformation[] | AllInformation>([] as any);

  const loadFns: Function[] = [];

  onMounted(async () => {
    if (Array.isArray(params)) {
      const tasks = params.map(e => fetchDataInfo(e, limit));
      data.value = (await Promise.all(tasks)) as any;
    } else {
      data.value = await fetchDataInfo(params, limit);
    }

    loadFns.forEach(fn => fn());
  });

  function onLoad(fn: Function) {
    loadFns.push(fn);
  }

  return { data, onLoad };
}

export async function fetchDataInfo(params: FetchDataParams, limit?: number) {
  const result = new AllInformation();
  const p = { ...params, categoryName: undefined };
  let data = [] as number[];
  if (Array.isArray(params.categoryName) && p.categoryType) {
    data = (await api.HomePortal.GeCategoryPaged_GetAsync({ type: p.categoryType, limit: 99 }))?.items?.flatMap(v =>
      params.categoryName?.includes(v!.name!) ? v.id : []
    ) as number[];
  } else {
    p.categoryName = params.categoryName as any;
  }
  const res = await api.HomePortal.GetInformation_PostAsync({ limit, ...p }, data);
  result.tabName =
    p.displayName || p.categoryName || (p.categoryType != null ? models.CategoryType[p.categoryType] : '');
  result.news = res.items || [];
  result.total = res.totals!;
  return result;
}

/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-04-10 11:39:17
 */
import { defineStore } from 'pinia';
import * as api from '@/api';
import type * as models from '@/api/models';

export interface IStandardState {
  data: models.Standard[];
  keyData: Record<string, models.Standard>;
}

export const useStandardStore = defineStore('standard', {
  state: (): IStandardState => ({
    data: [], // list of all standard items (from the server)
    keyData: {}, // key data for quick reference (from the server)
  }),

  actions: {
    async getStandardAll() {
      try {
        const data = await api.Standards.FindAll_GetAsync({ name: '' });
        const keyData: Record<string, models.Standard> = {};
        data.forEach(e => {
          keyData[e.key] = e;
        });
        this.keyData = keyData; // for quick reference (from the server)
        this.data = data; // for quick access (from the client)
        return { data, keyData };
      } catch (error) {
        this.data = [];
        this.keyData = {};
        return {
          data: [],
          keyData: {},
        };
      }
    },
  },
});

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  AppstoreAddOutlined,
  CommentOutlined,
  ContainerOutlined,
  ExperimentOutlined,
  FileDoneOutlined,
  PartitionOutlined,
  ReadOutlined,
  ShareAltOutlined,
  WalletOutlined,
} from '@ant-design/icons-vue';
import type { CategoryType } from '@/api/models';
import * as api from '@/api';

const router = useRouter();

const menuOption = computed(() => {
  return router.options.routes.filter(item => item.meta!.menuBar);
});

const icons = ref([
  PartitionOutlined,
  ShareAltOutlined,
  ReadOutlined,
  FileDoneOutlined,
  ExperimentOutlined,
  WalletOutlined,
  ContainerOutlined,
  AppstoreAddOutlined,
  CommentOutlined,
]);

const currentPath = ref('/');

function goRoute(r: any, id: number | null) {
  currentPath.value = r;
  // const { href } = router.resolve({
  //   path: r,
  //   query: {
  //     id,
  //   },
  // });
  // window.open(href, '_blank');
  router.push({ path: r, query: { id } });
}

const optionData = ref<any>([]);

async function getData() {
  try {
    menuOption.value.forEach(async item => {
      const { path } = item;
      const name = item.meta?.title as any;
      const type = item.meta?.type as CategoryType;
      const data = { name, path, child: [] } as any;
      optionData.value.push(data);
      const res = await api.HomePortal.GeCategoryPaged_GetAsync({
        type,
        offset: 0,
        limit: 99,
      });
      data.child = res.items?.filter(item => item.menuShow === true);
    });
  } catch (error) {}
}

function active(op) {
  if (op.path === '/' && router.currentRoute.value.path !== '/home') {
    return false;
  }
  return router.currentRoute.value.path.includes(op.path);
}

onMounted(() => {
  getData();
});
</script>

<template>
  <a-layout-header class="header">
    <ul class="menu">
      <li v-for="(option, index) in optionData" :key="index" class="nav-item" :class="{ active: active(option) }">
        <a v-if="option.name === '首页'" class="ant-dropdown-link" @click="goRoute('/home', null)">
          <div><c-icon-home-outlined /> <span class="text">首页</span></div>
        </a>

        <a-dropdown v-else>
          <a class="ant-dropdown-link" @click.prevent>
            <div>
              <component :is="icons[index]" />
              <span class="text">{{ option.name }}</span>
            </div>
          </a>
          <template #overlay>
            <a-menu mode="horizontal">
              <a-menu-item v-for="child in option.child" :key="child.id">
                <a @click="goRoute(option.path, child.id)">
                  {{ child.name }}
                </a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </li>
    </ul>
  </a-layout-header>
</template>

<style scoped lang="less">
:deep(.ant-dropdown-menu-item) {
  text-align: center;
}
.header {
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: -20px;

  background: transparent;

  .menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    // li {
    //   &.active .ant-dropdown-link {
    //     background-color: #fff;
    //     > div {
    //       background-color: #003a5f;
    //       color: #fff;
    //     }
    //   }
    //   .ant-dropdown-link {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     width: 116px;
    //     height: 94px;
    //     background-color: #003a5f;
    //     position: relative;
    //     text-align: center;
    //     clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    //     margin: 0 20px;
    //     font-size: 16px;
    //     transition: all 0.35s;

    //     &:hover {
    //       background-color: #fff;

    //       > div {
    //         background-color: #003a5f;
    //         color: #fff;
    //       }
    //     }
    //     > div {
    //       transition: all 0.35s;
    //       width: calc(100% - 10px);
    //       height: calc(100% - 10px);
    //       display: flex;
    //       flex-direction: column;
    //       line-height: 26px;
    //       background-color: #fff;
    //       clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    //       color: #003a5f;

    //       .anticon {
    //         font-size: 42px;
    //         margin-top: 10px;
    //       }
    //       .text {
    //         margin-top: 6px;
    //         font-size: 14px;
    //       }
    //     }
    //   }
    // }

    li {
      &.active .ant-dropdown-link {
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.1);
        background-color: #003a5f;
        color: #fff;
      }
      .ant-dropdown-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 116px;
        height: 46px;
        // transform: skew(20deg);
        // border: 2px solid #003a5f;
        text-align: center;
        // transition: all 0.35s;
        margin: 0 20px;
        font-size: 16px;
        color: #000;
        // background-color: rgba(0, 58, 95, 0.8);
        // box-shadow: inset 0 0 10px #003a5f;
        > div {
          // transform: skew(-20deg);
        }

        .text {
          margin-left: 4px;
        }

        &:hover {
          background-color: #003a5f;
          color: #fff;
        }
      }
      // &:last-child {
      //   .ant-dropdown-link {
      //     transform: skew(0deg);
      //   }
      // }
    }
  }
}

:deep(.ant-layout-header) {
  padding: 0;
  height: 90px;
  line-height: normal;
}
</style>

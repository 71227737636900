/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-04-10 10:20:12
 */

export class LoginData {
  // username: string;
  // password: string;
}

export class UserService {
  static login(data: LoginData) {}

  static logout() {}

  static getUserInfo() {}
}

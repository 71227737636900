/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-04-10 10:04:52
 */
import type { LoginData } from '@/api/user';
import { UserService } from '@/api/user';
import { defineStore } from 'pinia';

export class User {
  token: string | null = '';

  refresh_token: string | null = '';

  expires_time: string | null = null;

  remember = false; // 记住密码，false => 关闭浏览器清除缓存

  /** 姓名 */
  name = '';

  /** 用户名（登录名） */
  userName = '';

  userId: string | null = null;

  roles: Role[] | null | undefined = [];
}

export interface IUserState {
  active: User;

  userList: User[];
}

export const useUserStore = defineStore('user', {
  state: (): IUserState => ({
    active: new User(),
    userList: [],
  }),
  getters: {
    userInfo(): User {
      return this.active;
    },
    getToken(): string | null {
      if (this.active.expires_time && new Date() > new Date(this.active.expires_time)) {
        const userStore = useUserStore();
        userStore.logout();
      }
      return this.active.token;
    },
    isSimulation(): boolean {
      const [firstUser] = this.userList;
      return this.userList.length > 1 && firstUser.userId !== this.active.userId;
    },
  },
  actions: {
    async signIn(data: LoginData): Promise<any> {
      return UserService.login(data);
    },
    async setToken(obj: { refresh_token: string; expires_time: string; token: string }) {
      this.setData(obj);
      try {
        await this.getInfo();
      } catch (error) {
        console.log('%c [ Login ]-59', 'font-size:13px; background:pink; color:#bf2c9f;', error);
      }
    },
    async getInfo(): Promise<User> {
      const data: User = new User();
      try {
        const token = this.getToken as unknown as string;
        data.refresh_token = this.active.refresh_token;
        data.expires_time = this.active.expires_time;
        data.token = token;

        if (!token || token === 'invalid-access-token') {
          return data;
        }
        try {
          const res = await UserService.getUserInfo();

          if (this.userList.map(e => e.userId)?.indexOf(data.userId) === -1) {
            this.userList.push(data);
          }
        } catch (error) {
          throw new Error('获取用户信息失败');
        }

        const info = { active: data, userList: this.userList };

        this.setInfo(info);
        return data;
      } catch (error) {
        console.log('%c [ error ]-80', 'font-size:13px; background:#2a21a2; color:#6e65e6;', error);
      }
      return data;
    },
    async logout() {
      await UserService.logout();
      this.clear();
    },
    stopUser() {
      if (this.userList.length > 1) {
        this.resetInfo();
        const [first] = this.userList;
        Object.assign(this.active, first);
      } else {
        this.clear();
      }
    },
    /**
     * 如果本地userList存储有该用户信息 则切换用户 不再调用登录接口（可以更新token 如有）
     * @param {string} account userName Or userId Or any key
     * @param {string} [key='userId']
     */
    switchUser(account: string, key = 'userName'): User {
      if (this.userList.length > 1) {
        const index = this.userList.findIndex(e => e[key as keyof typeof e] === account);
        if (index !== -1) {
          const user = this.userList[index];
          if (user.expires_time && new Date() > new Date(user.expires_time)) {
            this.logout();
            throw new Error('该用户登录过期');
          }
          this.resetInfo();
          Object.assign(this.active, user);
          return user;
        }
      }
      return this.active;
    },
    setInfo(partial: Partial<IUserState>) {
      this.$patch(partial);
    },
    clear() {
      this.$reset();
    },
    resetInfo() {
      Object.assign(this.active, new User());
    },
    setData(obj: { refresh_token: string; expires_time: string; token: string }) {
      this.active.refresh_token = obj.refresh_token;
      this.active.expires_time = obj.expires_time;
      this.active.token = obj.token;
    },
  },
});

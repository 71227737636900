<script lang="ts" setup>
import { useFetchData } from '@/views/home/hooks/infoType';
import { ref } from 'vue';

const copyright = import.meta.env.VITE_APP_COPYRIGHT;

const currentYear = ref(new Date().getFullYear());

const { data: lxfx } = useFetchData({ categoryName: '联系方式' }, 1);
</script>

<template>
  <a-layout-footer style="padding: 0">
    <div class="footer">
      <div class="footer-container">
        <div style="text-align: left">
          <div>版权所有（C）广西行动学习研究会</div>
          <div>技术支持： ©{{ currentYear }} {{ copyright }}</div>
          <div>桂ICP备2020007181号-1</div>
          <template v-if="lxfx.news?.[0]?.description">
            <div v-for="item in lxfx.news[0].description?.split('\n')" :key="item">
              {{ item }}
            </div>
          </template>
        </div>
        <div class="footer-link">
          <div class="qr-code">
            <img style="width: 100px; height: 100px" src="../assets/images/qrcode.png" /><span>微信公众号</span>
          </div>
        </div>
      </div>
    </div>
  </a-layout-footer>
</template>

<style scoped lang="less">
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 196px;
  background-color: @primary-color;
  color: #fff;
  text-align: center;

  .footer-link {
    margin-left: 60px;
    display: flex;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 1140px;
  }

  .qr-code {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      margin-top: 6px;
    }
  }
}
@media (min-width: 1440px) {
  .footer-container {
    width: 960px;
  }
}

@media (min-width: 1441px) {
  .footer-container {
    width: 1140px;
  }
}
</style>

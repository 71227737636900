/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-04-10 11:37:46
 */
import { defineStore } from 'pinia';
import type { RouteRecordNormalized } from 'vue-router';

export interface IAppState {
  theme: string;
  colorWeak: boolean;
  navbar: boolean;
  menu: boolean;
  topMenu: boolean;
  hideMenu: boolean;
  menuCollapse: boolean;
  footer: boolean;
  themeColor: string;
  menuWidth: number;
  globalSettings: boolean;
  device: string;
  tabBar: boolean;
  menuFromServer: boolean;
  serverMenu: RouteRecordNormalized[];
  [key: string]: unknown;
}

export const useAppStore = defineStore('app', {});

/*
 * @Descripttion:
 * @Author: Xinner
 * @Date: 2021-01-20 16:29:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-16 16:33:31
 */
import type { Router } from 'vue-router';

export default (router: Router) => {
  /** 全局路由守卫，处理动态权限 */
  router.beforeEach(async (to, from, next) => {
    next();
  });

  router.afterEach(to => {
    if (to.meta.title) {
      if (to.meta.title === '首页') {
        document.title = `${import.meta.env.VITE_APP_TITLE}`;
      } else {
        document.title = `${to.meta.title} | ${import.meta.env.VITE_APP_TITLE}`;
      }
    }
  });
};

/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-04-10 09:55:38
 */
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

export * from './modules/app';
export * from './modules/standard';
export * from './modules/user';

const pinia = createPinia();

pinia.use(
  createPersistedState({
    auto: true,
  })
);

export default pinia;

/*
 * @Author: xinner
 * @Date: 2022-03-25 09:56:02
 * @LastEditors: nono
 * @LastEditTime: 2023-06-29 10:01:12
 * @Description:
 */
import { createApp } from 'vue';
import '@/utils/remote-request';
import '@/assets/default.less';
import stores from '@/stores';
import App from './App.vue';
import router, { setupRouter } from './router';
import 'virtual:uno.css';

const app = createApp(App);

app.use(stores);

setupRouter(app);

// app.use(permission);

router.isReady().then(() => app.mount('#app'));

<!--
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-05-23 16:25:07
-->
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();

function onSearch(val: string) {
  const routeUrl = router.resolve({
    path: '/searchweb',
    query: {
      value: val,
    },
  });
  window.open(routeUrl.href, '_blank');
}
</script>

<template>
  <div class="c2-container">
    <!-- banner -->

    <!-- 导航 -->
    <a-layout style="min-height: 100vh">
      <div class="head">
        <div class="banner">
          <div class="banner-box">
            <a class="bannner-left">
              <img src="@/assets/images/logo1.png" />
            </a>
            <div class="banner-right">
              <div class="banner-right-box">
                <div class="banner-right-text"></div>
                <a-input-search placeholder="请输入关键字" enter-button style="width: 468px" @search="onSearch" />
              </div>
            </div>
          </div>
        </div>
        <Header />
      </div>
      <a-layout-content class="c2-layout-content">
        <router-view :key="route.fullPath"></router-view>
      </a-layout-content>
      <Footer />
    </a-layout>
  </div>
</template>

<style scoped lang="less">
@tab-color: #1f60b5;

.ant-layout {
  background: #fff;
}

.w1920 {
  max-width: 1920px;
}

.banner {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  .banner-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin-left: 10px;
    }

    .bannner-left {
      width: 515px;
      display: flex;
      align-items: center;

      img {
        height: 120px;
      }

      h2 {
        margin: 0;
        padding: 0;
        font-family: cursive;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 3px;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    .banner-right {
      width: 470px;
      display: flex;
      align-items: center;

      .banner-right-text {
        margin-bottom: 10px;
      }
    }
  }
}

.head {
  border-bottom: 1px solid #ddd;
  height: fit-content;
  background-color: #eee;
  background-image: url(../assets/images/head.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.c2-layout-content {
  background-color: #eeeeee9f;
}
</style>

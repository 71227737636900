<!--
 * @Description: 
 * @Author: luckymiaow
 * @Date: 2022-04-15 16:15:09
 * @LastEditors: 景 彡
-->

<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh_CN');
</script>

<template>
  <!-- <Chid /> -->
  <c-config-provider :locale="zhCN" :modal="{ maskClosable: false }">
    <router-view />
  </c-config-provider>
</template>

<style>
#app {
  min-width: 1400px;
  /* height: 100%; */
  background-color: #f0f2f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 全局设置modal溢出滚动 */
.ant-modal-body {
  overflow: auto;
}
</style>

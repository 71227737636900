export enum CategoryType {
  组织概括 = 0,
  新闻频道 = 1,
  信息公示 = 2,
  研究成果 = 3,
  行动学习 = 4,
  全资源库 = 5,
  品牌活动 = 6,
  行者互助 = 7,
}

/*
 * @Descripttion:
 * @Author: Xinner
 * @Date: 2021-03-19 10:36:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-11 16:00:07
 */

import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { RequestOptions } from '@/.generated/apis';
import { apiOptions } from '@/.generated/apis';
import { Modal, message, notification } from 'ant-design-vue';
import { whiteListTest } from '@/router/whiteList';
import type { Information } from '@/api/models';
import router from '@/router';
import { useUserStore } from '../stores/modules/user';

function detailUrl(information: Information) {
  const routeUrl = router.resolve({
    path: '/detail',
    query: {
      informationId: information.informationId,
    },
  });
  return routeUrl.href;
}

// eslint-disable-next-line func-names
apiOptions.ajaxRequestAsync = async function <TData, TResult>(
  options: RequestOptions<TData>
): Promise<TResult | AxiosResponse<TResult>> {
  const { getToken } = useUserStore();

  const headers = { ...(options.headers || {}) };
  if (getToken) {
    headers.Authorization = `Bearer ${getToken}`;
  }

  const result = await axios.request<TData, AxiosResponse<TResult>>({
    ...options,
    headers,
    withCredentials: false,
    // baseURL: import.meta.env.VITE_APP_PROXY_TARGET,
  });

  if (options.responseType === 'blob') return result;

  const { data } = result;

  if (options.url.includes('/api/HomePortal/GetInformation') && data) {
    const temp = data as any;
    if (temp.data?.items) {
      temp.data?.items.forEach(v => {
        if (!v.url) v.url = detailUrl(v);
      });
    }
  }

  return data;
};

function errMessage() {
  const { logout } = useUserStore();

  if (!whiteListTest(window.location.hash.split('?')[0])) {
    Modal.info({
      content: '您的登录凭证已经过期，请重新登录',
      title: '重新登录',
      okText: '重新登录',
      onOk: () => {
        logout();
        sessionStorage.clear();
        location.href = '/#/login';
        Modal.destroyAll();
      },
    });
  }
}

// respone 拦截器
axios.interceptors.response.use(
  async response => {
    const { setToken } = useUserStore();

    const { data } = response;
    if (data.code === 200) {
      const token = response.headers['x-access-token'] as string;

      if (token && token.length > 0) {
        let defaultExpires = '21600'; // 6小时
        const expires = response.headers['x-access-token-expires-in'] as string;
        if (expires && expires.length > 0) {
          defaultExpires = expires;
        }
        const time = new Date(new Date().getTime() + parseInt(defaultExpires, 10) * 1000);
        await setToken({
          refresh_token: '',
          expires_time: time.toString(),
          token,
        });
      }
    } else if (data.code === 401) {
      errMessage();
    }
    if (Object.keys(data).includes('code')) {
      if (data.code !== 200) {
        console.log(`服务器操作错误：`, data.data);
        notification.error({
          message: '服务调用失败',
          description: `错误代码：${data.code}，异常消息：${data.message}`,
        });
        throw new Error(data.message);
      }
    }
    return response;
  },
  error => {
    let msg = error.toString();
    if (error.response) {
      console.log('API ERROR error.response:', error.response);
      switch (error.response.status) {
        case 401:
          errMessage();
          break;
        case 403:
          msg = `code=403: ${error.response.data.message}`;
          message.error(msg);
          break;
        case 500:
          msg = `code=500: 服务器错误:${error.response.data} `;
          console.log(msg, error);
          message.error(msg);
          break;
        default:
          msg = `code=${error.response?.status ?? 400}: ${error.response.data.message}`;
          message.error(msg);
      }
    } else {
      console.log('API ERROR:', error);
      Modal.confirm({ content: `错误:response=${error}`, title: '错误' });
    }
    return Promise.reject(msg);
  }
);

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import type { RouterOptions } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import type { App } from 'vue';
import { ref } from 'vue';
import { CategoryType } from '@/api/models';
import createRouterGuards from './hook/routerPermission';

const staticRoutes = [
  {
    name: '',
    path: '/',
    redirect: '/home',
    meta: { title: '首页', hidden: false },
  },
  {
    name: 'Home',
    path: '/home',
    component: () => import('@/views/home/index.vue'),
    meta: { title: '首页', hidden: true, menuBar: true },
  },
  {
    path: '/mechanism',
    // component: InfoLayout,
    component: DefaultLayout,
    meta: { title: '组织概况', type: CategoryType.组织概括, hidden: true, menuBar: true },
    children: [
      {
        path: '',
        name: 'Mechanism',
        component: () => import('@/views/mechanism/index.vue'),
      },
    ],
  },
  {
    path: '/info-dynamic',
    component: DefaultLayout,
    meta: { title: '新闻频道', type: CategoryType.新闻频道, hidden: true, menuBar: true },
    children: [
      {
        path: '',
        name: 'InfoDynamic',
        component: () => import('@/views/Info-dynamic/index.vue'),
      },
    ],
  },
  {
    path: '/information-disclosure',
    component: DefaultLayout,
    meta: { title: '信息公示', type: CategoryType.信息公示, hidden: true, menuBar: true },
    children: [
      {
        path: '',
        name: 'InformationDisclosure',
        component: () => import('@/views/information-disclosure/index.vue'),
      },
    ],
  },
  {
    path: '/research-findings',
    component: DefaultLayout,
    meta: { title: '研究成果', type: CategoryType.研究成果, hidden: true, menuBar: true },
    children: [
      {
        path: '',
        name: 'ResearchFindings',
        component: () => import('@/views/research-findings/index.vue'),
      },
    ],
  },
  {
    path: '/project-construction',
    component: DefaultLayout,
    meta: { title: '行动学习+', type: CategoryType.行动学习, hidden: true, menuBar: true },
    children: [
      {
        path: '',
        name: 'ProjectConstruction',
        component: () => import('@/views/project-construction/index.vue'),
      },
    ],
  },
  // {
  //   path: '/course',
  //   component: DefaultLayout,
  //   meta: { title: '全资源库', hidden: true, menuBar: true },
  //   children: [
  //     {
  //       path: '',
  //       name: 'Course',
  //       component: () => import('@/views/course/index.vue'),
  //     },
  //   ],
  // },
  {
    path: '/full-resource-library',
    component: DefaultLayout,
    meta: { title: '全资源库', type: CategoryType.全资源库, hidden: true, menuBar: true },
    children: [
      {
        path: '',
        name: 'FullResourceLibrary',
        component: () => import('@/views/full-resource-library/index.vue'),
      },
    ],
  },
  {
    path: '/brand-activities',
    component: DefaultLayout,
    meta: { title: '品牌活动', type: CategoryType.品牌活动, hidden: true, menuBar: true },
    children: [
      {
        path: '',
        name: 'BrandActivities',
        component: () => import('@/views/brand-activities/index.vue'),
      },
    ],
  },
  {
    path: '/interaction',
    component: DefaultLayout,
    meta: { title: '行者互动', hidden: true, type: CategoryType.行者互助, menuBar: true },
    children: [
      {
        path: '',
        name: 'interaction',
        component: () => import('@/views/traveler-interaction/index.vue'),
      },
    ],
  },

  {
    path: '/detail',
    component: DefaultLayout,
    meta: { title: '资讯详情', hidden: true },
    name: 'Detail',
    redirect: '/detail',
    children: [
      {
        path: '',
        name: 'InfoDetail',
        component: () => import('@/views/Info-detail/index.vue'),
      },
    ],
  },
  {
    path: '/searchweb',
    component: DefaultLayout,
    meta: { title: '搜索结果', hidden: true },
    children: [
      {
        path: '',
        name: 'SearchWeb',
        component: () => import('@/views/query-list/index.vue'),
      },
    ],
  },
  {
    path: '/logoutReload',
    meta: { title: '', hidden: true },
    component: () => import('@/views/logoutReload.vue'),
  },
  {
    path: '/logout',
    meta: { title: '', hidden: true },
    children: [
      {
        path: '',
        component: () => import('@/views/logout.vue'),
        name: 'Logout',
        meta: {
          title: '注销登录',
          icon: 'example',
          noCache: true,
          hidden: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '', hidden: true },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/404',
    name: 'notFound',
    meta: { title: '', hidden: true },
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    meta: { title: '', hidden: true },
    redirect: '/404',
  },
];

/* 首页 */
export const HomeRoutePath = ref('Home');

const config: RouterOptions = {
  history: createWebHistory(),
  // history: createWebHashHistory(),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
  routes: staticRoutes,
};

const router = createRouter(config);

/**
 * @description: 页面初始化
 * @param undefined
 * @return {*}
 * @author: luckymiaow
 */
export async function setupRouter(app: App<Element>) {
  createRouterGuards(router);

  app.use(router);
}

export default router;
